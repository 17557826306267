var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.path === '/System/Status')?_c('div',[_c('KTPortlet',{staticStyle:{"width":"500px","max-width":"90%","margin":"0 auto","xbackground":"var(--dark)"}},[_c('template',{slot:"body"},[_c('div',{staticClass:"loginBody",staticStyle:{"text-align":"center"}},[_c('h1',{staticStyle:{"color":"var(--primary)"}},[_vm._v("Service status")])])])],2),_c('div',{staticStyle:{"width":"500px","max-width":"90%","margin":"0 auto"}},[(_vm.serviceStatuses !== null)?_c('div',{staticClass:"row flex",staticStyle:{"padding":"10px","padding-top":"30px"}},_vm._l((_vm.servers),function(server){return _c('div',{key:server,staticClass:"col-md-6 col-lg-6 col-xl-6"},[_c('div',{staticClass:"card card-custom card-rounded bg-light-danger card-stretch gutter-b"},[_c('div',{staticClass:"card-body my-3"},[_c('h3',{staticClass:"text-primary font-weight-bold font-size-sm"},[_c('v-chip',{staticClass:"ma-2",staticStyle:{"margin-left":"10px","text-align":"center"},attrs:{"color":`bg-${
                    _vm.$tools.isNullOrUndefined(_vm.serviceStatuses[server])
                      ? 'info'
                      : _vm.serviceStatuses[server] === true
                      ? 'success'
                      : 'danger'
                  } text-white`}},[_vm._v(_vm._s(server))])],1),_c('div',{staticClass:"font-weight-bold text-muted font-size-sm my-3"},[_vm._v(" "+_vm._s(_vm.getServiceDescription(server))+" ")])])])])}),0):_vm._e()])],1):_c('div',[(_vm.serviceStatuses !== null)?_c('div',{staticClass:"row flex",staticStyle:{"padding":"10px","padding-top":"10px"}},_vm._l((_vm.servers),function(server){return _c('div',{key:server,staticClass:"col-md-6 col-lg-6 col-xl-4"},[_c('div',{staticClass:"card card-custom card-rounded bg-light-danger card-stretch gutter-b"},[_c('div',{staticClass:"card-body my-3"},[_c('h3',{staticClass:"text-primary font-weight-bold font-size-sm"},[_c('v-chip',{staticClass:"ma-2",staticStyle:{"margin-left":"10px","text-align":"center"},attrs:{"color":`bg-${
                  _vm.$tools.isNullOrUndefined(_vm.serviceStatuses[server])
                    ? 'info'
                    : _vm.serviceStatuses[server] === true
                    ? 'success'
                    : 'danger'
                } text-white`}},[_vm._v(_vm._s(server))])],1),_c('div',{staticClass:"font-weight-bold text-muted font-size-sm my-3"},[_vm._v(" "+_vm._s(_vm.getServiceDescription(server))+" ")])])])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }