<template>
  <div v-if="$route.path === '/System/Status'">
    <KTPortlet
      style="
        width: 500px;
        max-width: 90%;
        margin: 0 auto;
        xbackground: var(--dark);
      "
    >
      <template slot="body">
        <div class="loginBody" style="text-align: center">
          <h1 style="color: var(--primary)">Service status</h1>
        </div>
      </template>
    </KTPortlet>
    <div
      style="
        width: 500px;
        max-width: 90%;
        margin: 0 auto;"
    >
      <div
        style="padding: 10px; padding-top: 30px"
        class="row flex"
        v-if="serviceStatuses !== null"
      >
        <div
          class="col-md-6 col-lg-6 col-xl-6"
          v-for="server in servers"
          v-bind:key="server"
        >
          <div
            class="card card-custom card-rounded bg-light-danger card-stretch gutter-b"
          >
            <!--begin::ody-->
            <div class="card-body my-3">
              <h3 class="text-primary font-weight-bold font-size-sm">
                <v-chip
                  style="margin-left: 10px; text-align: center;"
                  :color="
                    `bg-${
                      $tools.isNullOrUndefined(serviceStatuses[server])
                        ? 'info'
                        : serviceStatuses[server] === true
                        ? 'success'
                        : 'danger'
                    } text-white`
                  "
                  class="ma-2"
                  >{{ server }}</v-chip
                >
              </h3>
              <div class="font-weight-bold text-muted font-size-sm my-3">
                {{ getServiceDescription(server) }}
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      style="padding: 10px; padding-top: 10px"
      class="row flex"
      v-if="serviceStatuses !== null"
    >
      <div
        class="col-md-6 col-lg-6 col-xl-4"
        v-for="server in servers"
        v-bind:key="server"
      >
        <div
          class="card card-custom card-rounded bg-light-danger card-stretch gutter-b"
        >
          <!--begin::ody-->
          <div class="card-body my-3">
            <h3 class="text-primary font-weight-bold font-size-sm">
              <v-chip
                style="margin-left: 10px; text-align: center;"
                :color="
                  `bg-${
                    $tools.isNullOrUndefined(serviceStatuses[server])
                      ? 'info'
                      : serviceStatuses[server] === true
                      ? 'success'
                      : 'danger'
                  } text-white`
                "
                class="ma-2"
                >{{ server }}</v-chip
              >
            </h3>
            <div class="font-weight-bold text-muted font-size-sm my-3">
              {{ getServiceDescription(server) }}
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import Loader from "@/views/partials/content/Loader.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      serviceStatuses: null
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "config", "isAuthenticated", "logoTheme"]),

    servers() {
      return Object.keys(this.config.servers || {}).filter(
        x => x !== "enabled" && x !== "ws"
      );
    }
  },
  methods: {
    getServiceDescription(service) {
      if (service === "auth") {
        return "This service handles authentication for this portal";
      }
      if (service === "portal") {
        return "This service handles core functionality of the portal";
      }
      if (service === "networks") {
        return "This service handles the network management";
      }
      if (service === "telecoms") {
        return "This service handles the telecoms management";
      }
      if (service === "crm") {
        return "This service handles the CRM integration for the tenant";
      }
      if (service === "billing") {
        return "This service handles the billing integration for the tenant";
      }
      if (service === "advertising") {
        return "This service handles the advertising integration for the tenant";
      }
      return null;
    }
  },
  components: {
    KTPortlet
    //Loader
  },
  beforeDestroy() {
    this.$eventBus.off(`api-service-state`);
    this.$eventBus.off(`cron-30second`);
    this.$eventBus.off(`cron-minute`);
    this.$eventBus.off(`cron-30minute`);
  },
  mounted() {
    let self = this;

    let tObj = {};
    for (let service of this.servers) {
      tObj[service] = this.$serviceStatus(service);
    }
    this.$data.serviceStatuses = tObj;

    self.$eventBus.on(`api-service-state`, x => {
      //console.log("service status change: ", x);
      self.$data.serviceStatuses[x.service] = x.state;
    });

    self.$eventBus.emit("ws-force-service-recheck");

    let cronCounter = 0;
    //if (this.$route.path === "/System/SStatus") {
    self.$eventBus.on("cron-30second", () => {
      cronCounter++;
      self.$eventBus.emit("ws-force-service-recheck");

      if (cronCounter > 10) {
        self.$eventBus.off(`cron-30second`);
        self.$eventBus.on("cron-minute", () => {
          cronCounter++;
          self.$eventBus.emit("ws-force-service-recheck");
          if (cronCounter > 15) {
            self.$eventBus.off(`cron-minute`);
            self.$eventBus.on("cron-30minute", () => {
              //cronCounter++;
              self.$eventBus.emit("ws-force-service-recheck");
              //if (cronCounter > 16) {
              self.$eventBus.off(`cron-30minute`);
              //}
            });
          }
        });
      }
    });
    //}
  }
};
</script>